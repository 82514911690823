import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Components
import CategoryMenu         from "Components/Core/Menu/CategoryMenu";
import OfferMenu            from "Components/Core/Menu/OfferMenu";
import BikeMenu             from "Components/Core/Menu/BikeMenu";
import ClientMenu           from "Components/Core/Menu/ClientMenu";
import FavoriteMenu         from "Components/Core/Menu/FavoriteMenu";
import NotificationMenu     from "Components/Core/Menu/NotificationMenu";
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Data
import {
    MainMenu, getCatalogFile,
} from "Utils/App/Data";

// Styles
import "Styles/Components/Core/Menu.css";



/**
 * The Menu
 */
class Menu extends React.Component {
    // The Current State
    state = {
        menu : "",
    }

    // Timeouts
    openTimeout  = null;
    closeTimeout = null;



    /**
     * Shows the a Menu
     * @param {String} menu
     * @returns {Function}
     */
    showMenu = (menu) => () => {
        if (menu && !this.openTimeout) {
            this.clearTimeouts();
            this.openTimeout = window.setTimeout(this.openMenu(menu), 200);
        }
    }

    /**
     * Open the a Menu
     * @param {String} menu
     * @returns {Function}
     */
    openMenu = (menu) => () => {
        this.timeout = null;
        this.setState({ menu });

        const items = this.props.data[menu];
        if (menu === "client" || (items && items.length > 0)) {
            this.props.toggleMenu(true);
        } else {
            this.props.toggleMenu(false);
        }
    }

    /**
     * Hides the a Menu
     * @returns {Function}
     */
    hideMenu = () => {
        this.clearTimeouts();
        this.closeTimeout = window.setTimeout(this.closeMenu, 200);
    }

    /**
     * Closes the a Menu
     * @returns {Void}
     */
    closeMenu = () => {
        this.clearTimeouts();
        this.setState({ menu : "" });
        this.props.toggleMenu(false);
    }

    /**
     * Hides the a Menu
     * @returns {Void}
     */
    keepMenu = () => {
        this.clearTimeouts();
    }

    /**
     * Clears the Timeouts
     * @returns {Void}
     */
    clearTimeouts() {
        if (this.openTimeout) {
            window.clearTimeout(this.openTimeout);
            this.openTimeout = null;
        }
        if (this.closeTimeout) {
            window.clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { credential, data, notification                } = this.props;
        const { category, offer, bike, favorite, dollar, cart } = data;
        const { menu                                          } = this.state;

        const showCategory     = menu === "category"     && category.length     > 0;
        const showOffer        = menu === "offer"        && offer.length        > 0;
        const showBike         = menu === "bike"         && bike.length         > 0;
        const showFavorite     = menu === "favorite"     && favorite.length     > 0;
        const showNotification = menu === "notification" && notification.length > 0;
        const showClient       = menu === "client";

        return <section className="menu-container">
            <Wrapper>
                <div className="menu-content">
                    <ul className="menu-nav menu-primary no-list">
                        {MainMenu.map(({ key, href, message, submenu }) => <li key={key}>
                            <HyperLink
                                variant="menu"
                                href={href}
                                message={message}
                                onMouseEnter={submenu ? this.showMenu(key) : null}
                                onMouseLeave={this.hideMenu}
                                onClick={this.closeMenu}
                                afterIcon={submenu ? "down" : ""}
                            />
                        </li>)}
                    </ul>
                    <ul className="menu-nav menu-secondary no-list">
                        {!!dollar && <li className="menu-dollar">
                            {NLS.format("HEADER_DOLAR", dollar)}
                        </li>}
                        <li>
                            <HyperLink
                                variant="menu"
                                href={getCatalogFile()}
                                target="_blank"
                                message="CATALOG_NAME"
                                isDownload
                            />
                        </li>
                    </ul>
                    <ul className="menu-nav menu-terciary no-list">
                        <li className="menu-user">
                            <HyperLink
                                variant="menu"
                                icon="account"
                                href="/cuenta"
                                message={credential}
                                onMouseEnter={this.showMenu("client")}
                                onMouseLeave={this.hideMenu}
                                onClick={this.closeMenu}
                                afterIcon="down"
                            />
                        </li>
                        <li>
                            <HyperLink
                                variant="icon"
                                icon="favorite"
                                href="/favoritos"
                                onMouseEnter={this.showMenu("favorite")}
                                onMouseLeave={this.hideMenu}
                                onClick={this.closeMenu}
                            />
                        </li>
                        <li>
                            <HyperLink
                                variant="icon"
                                icon="notifications"
                                href="/notificaciones"
                                onMouseEnter={this.showMenu("notification")}
                                onMouseLeave={this.hideMenu}
                                onClick={this.closeMenu}
                                badge={notification.length}
                            />
                        </li>
                        <li>
                            <HyperLink
                                variant="icon"
                                icon="cart"
                                href="/compras"
                                badge={cart}
                            />
                        </li>
                    </ul>
                </div>

                <CategoryMenu
                    open={showCategory}
                    data={category}
                    onEnter={this.keepMenu}
                    onLeave={this.hideMenu}
                    onClose={this.closeMenu}
                />
                <OfferMenu
                    open={showOffer}
                    data={offer}
                    onEnter={this.keepMenu}
                    onLeave={this.hideMenu}
                    onClose={this.closeMenu}
                />
                <BikeMenu
                    open={showBike}
                    data={bike}
                    onEnter={this.keepMenu}
                    onLeave={this.hideMenu}
                    onClose={this.closeMenu}
                />

                <ClientMenu
                    open={showClient}
                    onEnter={this.keepMenu}
                    onLeave={this.hideMenu}
                    onClose={this.closeMenu}
                />
                <FavoriteMenu
                    open={showFavorite}
                    data={favorite}
                    onEnter={this.keepMenu}
                    onLeave={this.hideMenu}
                    onClose={this.closeMenu}
                />
                <NotificationMenu
                    open={showNotification}
                    data={notification}
                    onEnter={this.keepMenu}
                    onLeave={this.hideMenu}
                    onClose={this.closeMenu}
                />
            </Wrapper>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        credential   : PropTypes.string.isRequired,
        data         : PropTypes.object.isRequired,
        notification : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            credential   : state.auth.credential.name,
            data         : state.store.header,
            notification : state.notification.list,
        };
    }
}

export default connect(Menu.mapStateToProps)(Menu);
