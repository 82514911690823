import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/HyperLink.css";



/**
 * A HyperLink
 */
class HyperLink extends React.Component {
    /**
     * Handles the Click
     * @param {Event} e
     * @returns {Void}
     */
    onClick = (e) => {
        const { onClick, href, tel, mail, whatsapp, platform, isDownload } = this.props;
        let handled = false;

        if (isDownload) {
            return;
        }
        if (onClick) {
            onClick(e);
            handled = true;
        }

        // Href Links
        if (href && !tel && !mail && !whatsapp) {
            // Internal Links that start with the URL
            if (Utils.hrefInApp(href)) {
                this.props.history.push(Utils.getUrl(href));
                handled = true;

            // Internal link that are paths
            } else if (!href.startsWith("http")) {
                this.props.history.push(href);
                handled = true;

            // Voucher paths in Android
            } else if (href.startsWith(process.env.REACT_APP_VOUCHER) && platform === "android") {
                navigator.app.loadUrl(href, { openExternal : true });
                handled = true;

            // Voucher paths in iOS
            } else if (href.startsWith(process.env.REACT_APP_VOUCHER) && platform === "ios") {
                window.cordova.InAppBrowser.open(href, "_system");
                // window.open(href, "_system");
                handled = true;
            }
        }

        // WhatsApp in iOS
        if (!handled && whatsapp && platform === "ios") {
            window.cordova.InAppBrowser.open(`https://api.whatsapp.com/send?phone=549${href}`, "_system");
            handled = true;
        }

        // If handled prevent default
        if (handled) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    /**
     * Returns the Href and Target
     * @returns {{href: String, target: String}}
     */
    getHref() {
        const { href, target, tel, mail, whatsapp, isApp } = this.props;
        const result = { href, target };

        if (tel) {
            result.href = `tel:${href}`;
        } else if (mail) {
            result.href = `mailto:${href}`;
        } else if (whatsapp) {
            if (isApp) {
                result.href   = `whatsapp://send?phone=549${href}`;
            } else {
                result.href   = `https://api.whatsapp.com/send?phone=549${href}`;
                result.target = "_blank";
            }
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isHidden, variant, message, children, icon, afterIcon, badge, isDownload,
            className, onMouseEnter, onMouseLeave, style,
        } = this.props;

        if (isHidden) {
            return <React.Fragment />;
        }
        const { href, target } = this.getHref();
        const content          = children || NLS.get(message);
        const iconClass        = icon && variant !== "icon" ? "link-with" : "";

        return <a
            className={`link link-${variant} ${className} ${iconClass}`}
            href={href}
            target={target}
            onClick={this.onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            download={isDownload ? "" : null}
            style={style}
        >
            {!!icon      && <Icon className="link-preicon" variant={icon} />}
            {!!content   && <span className="link-content">{content}</span>}
            {!!afterIcon && <Icon className="link-aftericon" variant={afterIcon} />}
            {badge > 0   && <span className="badge">{badge}</span>}
        </a>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history      : PropTypes.object.isRequired,
        platform     : PropTypes.string,
        isHidden     : PropTypes.bool,
        isApp        : PropTypes.bool,
        variant      : PropTypes.string,
        message      : PropTypes.string,
        href         : PropTypes.string,
        target       : PropTypes.string,
        tel          : PropTypes.bool,
        mail         : PropTypes.bool,
        whatsapp     : PropTypes.bool,
        icon         : PropTypes.string,
        afterIcon    : PropTypes.string,
        badge        : PropTypes.number,
        isDownload   : PropTypes.bool,
        className    : PropTypes.string,
        onClick      : PropTypes.func,
        onMouseEnter : PropTypes.func,
        onMouseLeave : PropTypes.func,
        style        : PropTypes.object,
        children     : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden   : false,
        variant    : "primary",
        href       : "#",
        target     : "_self",
        badge      : 0,
        isDownload : false,
        className  : "",
        style      : {},
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            platform : state.core.platform,
            isApp    : state.core.isApp,
        };
    }
}

export default withRouter(connect(HyperLink.mapStateToProps)(HyperLink));
