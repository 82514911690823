import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Data
import {
    MainMenu, getClientMenu, getCatalogFile,
} from "Utils/App/Data";

// Styles
import "Styles/Components/Core/Navigation.css";



/**
 * The Navigation
 */
class Navigation extends React.Component {
    // The current state
    state = {
        menu : "",
    }

    /**
     * Handles the Click
     * @param {String} menu
     * @param {Object} submenu
     * @returns {Function}
     */
    handleClick = (menu, submenu) => () => {
        if (!submenu) {
            this.props.onClose();
        } else if (this.state.menu === menu) {
            this.setState({ menu : "" });
        } else {
            this.setState({ menu });
        }
    }

    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { open, credential, header, notification, onClose, logout } = this.props;
        const { menu } = this.state;

        const clientMenu = getClientMenu(credential.isPotential);

        return <section className={"navigation-container" + (open ? " navigation-open" : "")}>
            <header className="navigation-header">
                <Icon variant="account" />
                <div>
                    <HyperLink
                        className="navigation-title"
                        variant="black"
                        href="/cuenta"
                        message={NLS.format("HEADER_WELCOME", credential.name)}
                        onClick={onClose}
                    />
                    <HyperLink
                        className="navigation-notifications"
                        variant="black"
                        href="/notificaciones"
                        message={NLS.pluralize("HEADER_NOTIFICATIONS", notification.length)}
                        onClick={onClose}
                    />
                </div>
            </header>
            <div className="navigation-content">
                <ul className="navigation-list no-list">
                    {MainMenu.map(({ key, href, message }) => <li key={key}>
                        <HyperLink
                            variant="none"
                            icon={key}
                            href={href}
                            message={message}
                            onClick={onClose}
                        />
                    </li>)}
                </ul>

                <ul className="navigation-list no-list">
                    {!!credential.whatsapp && <li>
                        <HyperLink
                            variant="none"
                            icon="whatsapp"
                            href={credential.whatsapp}
                            message={NLS.format("HEADER_WHATSAPP_NUMBER", credential.whatsapp)}
                            whatsapp
                        />
                    </li>}
                    <li>
                        <HyperLink
                            variant="none"
                            icon="phone"
                            href={credential.phone}
                            message={NLS.format("HEADER_PHONE_NUMBER", credential.phone)}
                            tel
                        />
                    </li>
                    <li>
                        <HyperLink
                            variant="none"
                            icon="money"
                            message={NLS.format("HEADER_DOLAR_PRICE", header.dollar)}
                        />
                    </li>
                    <li>
                        <HyperLink
                            variant="none"
                            icon="catalog"
                            href={getCatalogFile()}
                            target="_blank"
                            message="CATALOG_NAME"
                            onClick={onClose}
                            isDownload
                        />
                    </li>
                    <li>
                        <HyperLink
                            variant="none"
                            icon="cart"
                            href="/compras"
                            message={NLS.format("CART_TITLE_FULL", header.cart)}
                            onClick={onClose}
                        />
                    </li>
                </ul>

                <ul className="navigation-list no-list">
                    {clientMenu.map(({ key, href, message, submenu }) => <li key={key}>
                        <HyperLink
                            variant="none"
                            icon={key}
                            href={!submenu ? href : ""}
                            message={message}
                            afterIcon={submenu ? (key === menu ? "down" : "right") : ""}
                            onClick={this.handleClick(key, submenu)}
                        />
                        {key === menu ? <ul className="no-list">
                            {submenu.map(({ key, href, message }) => <li key={key}>
                                <HyperLink
                                    variant="none"
                                    href={href}
                                    message={message}
                                    onClick={onClose}
                                />
                            </li>)}
                        </ul> : ""}
                    </li>)}
                    <li>
                        <HyperLink
                            variant="none"
                            icon="favorite"
                            href="/favoritos"
                            message="FAVORITES_TITLE"
                            onClick={onClose}
                        />
                    </li>
                    <li>
                        <HyperLink
                            variant="none"
                            icon="history"
                            href="/historial"
                            message="HISTORY_TITLE"
                            onClick={onClose}
                        />
                    </li>
                    <li>
                        <HyperLink
                            variant="none"
                            icon="logout"
                            message="CLIENT_LOGOUT"
                            onClick={logout}
                        />
                    </li>
                </ul>
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout       : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        credential   : PropTypes.object.isRequired,
        header       : PropTypes.object.isRequired,
        notification : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            credential   : state.auth.credential,
            header       : state.store.header,
            notification : state.notification.list,
        };
    }
}

export default connect(Navigation.mapStateToProps, { logout })(Navigation);
