import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import { logout }           from "Actions/Core/AuthActions";
import NLS                  from "Utils/App/NLS";

// Sections
import BlankPage            from "./BlankPage";
import BankPage             from "./BankPage";
import AccountPage          from "./Account/AccountPage";
import InvoicePage          from "./Voucher/InvoicePage";
import ReceiptPage          from "./Voucher/ReceiptPage";
import CreditNotePage       from "./Voucher/CreditNotePage";
import DebitNotePage        from "./Voucher/DebitNotePage";
import OrderList            from "./Order/OrderList";
import OrderPage            from "./Order/OrderPage";
import OrderGroupList       from "./Order/OrderGroupList";
import OrderGroupPage       from "./Order/OrderGroupPage";
import QueryList            from "./Query/QueryList";
import QueryPage            from "./Query/QueryPage";
import QueryCreate          from "./Query/QueryCreate";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import UserRoute            from "Components/Utils/Route/UserRoute";

// Data
import {
    getClientMenu, VoucherMenu, FooterMenu, getCatalogFile,
} from "Utils/App/Data";

// Styles
import "Styles/Components/Client/Client.css";



/**
 * The Client Container
 */
class ClientContainer extends React.Component {
    /**
     * Returns the Title Depending on the Path
     * @param {String} path
     * @returns {String}
     */
    getTitle(path) {
        let result = "CLIENT_TITLE";
        if (path.startsWith("/cuenta/bancos")) {
            result = "BANKS_NAME";
        } else if (path.startsWith("/cuenta/datos")) {
            result = "ACCOUNT_NAME";
        } else if (path.startsWith("/cuenta/comprobantes/facturas")) {
            result = "VOUCHERS_INVOICES";
        } else if (path.startsWith("/cuenta/comprobantes/recibos")) {
            result = "VOUCHERS_RECEIPTS";
        } else if (path.startsWith("/cuenta/comprobantes/notas-de-credito")) {
            result = "VOUCHERS_CREDIT_NOTES";
        } else if (path.startsWith("/cuenta/comprobantes/notas-de-debito")) {
            result = "VOUCHERS_DEBIT_NOTES";
        } else if (path.startsWith("/cuenta/pedidos") || path.startsWith("/cuenta/grupo-pedidos")) {
            result = "ORDERS_NAME";
        } else if (path.startsWith("/cuenta/pedido")) {
            result = "ORDERS_SINGULAR";
        } else if (path.startsWith("/cuenta/grupo-pedido")) {
            result = "ORDERS_GROUP_SINGULAR";
        } else if (path.startsWith("/cuenta/consultas")) {
            result = "QUERIES_NAME";
        } else if (path.startsWith("/cuenta/consulta")) {
            result = "QUERIES_SINGULAR";
        }
        return result;
    }

    /**
     * Returns the ClassName for the Navigation Link
     * @param {String} icon
     * @param {String} path
     * @param {String} url
     * @returns {String}
     */
    getClassName(icon, path, url) {
        const result = [];
        if (icon === "voucher" && path.startsWith(url)) {
            result.push("client-down");
        }
        if (icon === "voucher") {
            result.push("client-sub");
        }
        if (icon === "cart" && path.startsWith("/cuenta/pedido")) {
            result.push("client-selected");
        } else if (icon === "cart" && path.startsWith("/cuenta/grupo-pedido")) {
            result.push("client-selected");
        } else if ((icon === "query" || icon === "cart") && path.startsWith(url)) {
            result.push("client-selected");
        } else if (url === path) {
            result.push("client-selected");
        }
        return result.join(" ");
    }

    /**
     * Returns the Back Url
     * @param {String} path
     * @returns {String}
     */
    getBackUrl(path) {
        const { isTablet, type, page } = this.props;
        let result = isTablet ? "/cuenta" : "";

        if (path === "/cuenta" || path === "/cuenta/comprobantes") {
            result = "";
        } else if (isTablet && path.startsWith("/cuenta/comprobantes")) {
            result = "/cuenta/comprobantes";
        } else if (path.startsWith("/cuenta/pedido") && !path.startsWith("/cuenta/pedidos")) {
            result = "/cuenta/pedidos";
        } else if (path === "/cuenta/consultas/crear" || (path.startsWith("/cuenta/consulta") && !path.startsWith("/cuenta/consultas"))) {
            result = "/cuenta/consultas";
        }
        if (result && type) {
            result = `${result}/${type}`;
        }
        if (result && page > 1) {
            result = `${result}/${page}`;
        }
        return result;
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isTablet, isPotential, credential, dollar, logout, location } = this.props;

        const path       = location.pathname;
        const isVouchers = path === "/cuenta/comprobantes";
        const isBlank    = path === "/cuenta" || isVouchers;
        const title      = isTablet ? this.getTitle(path) : "CLIENT_TITLE";
        const subtitle   = !isTablet || isBlank ? credential.name : "";
        const backUrl    = this.getBackUrl(path);
        const clientMenu = getClientMenu(isPotential);

        return <Wrapper className={isBlank ? " client-menuonly" : ""} withSpacing>
            <Title
                className="client-title"
                message={title}
                submessage={subtitle}
                href={backUrl}
            />
            <div className="client-container">
                <div className="client-sidebar">
                    <Card className="client-mainnav">
                        <ul className="client-nav no-list">
                            {clientMenu.map(({ key, href, message }) => (
                                <li key={key}>
                                    <HyperLink
                                        className={this.getClassName(key, path, href)}
                                        variant="none"
                                        icon={key}
                                        href={href}
                                        message={message}
                                    />
                                    {key === "voucher" && path.startsWith(href) ? <ul className="no-list">
                                        {VoucherMenu.map(({ key, href, message }) => (
                                            <li key={key}>
                                                <HyperLink
                                                    className={path.startsWith(href) ? "client-selected" : ""}
                                                    variant="none"
                                                    href={href}
                                                    message={message}
                                                />
                                            </li>
                                        ))}
                                    </ul> : ""}
                                </li>
                            ))}
                            <li className="client-favorite">
                                <HyperLink
                                    variant="none"
                                    icon="favorite"
                                    href="/favoritos"
                                    message="FAVORITES_TITLE"
                                />
                            </li>
                            <li className="client-history">
                                <HyperLink
                                    variant="none"
                                    icon="history"
                                    href="/historial"
                                    message="HISTORY_TITLE"
                                />
                            </li>
                        </ul>
                    </Card>

                    <Card className="client-subnav">
                        <ul className="client-nav no-list">
                            {!!credential.whatsapp && <li>
                                <HyperLink
                                    variant="none"
                                    icon="whatsapp"
                                    href={credential.whatsapp}
                                    message={NLS.format("HEADER_WHATSAPP_NUMBER", credential.whatsapp)}
                                    whatsapp
                                />
                            </li>}
                            <li>
                                <HyperLink
                                    variant="none"
                                    icon="phone"
                                    href={credential.phone}
                                    message={NLS.format("HEADER_PHONE_NUMBER", credential.phone)}
                                    tel
                                />
                            </li>
                            <li>
                                <HyperLink
                                    variant="none"
                                    icon="money"
                                    message={NLS.format("HEADER_DOLAR_PRICE", dollar)}
                                />
                            </li>
                            <li>
                                <HyperLink
                                    variant="none"
                                    icon="catalog"
                                    href={getCatalogFile()}
                                    target="_blank"
                                    message="CATALOG_NAME"
                                    isDownload
                                />
                            </li>
                            <li>
                                <HyperLink
                                    variant="none"
                                    icon="news"
                                    href="/novedades"
                                    message="NEWS_NAME"
                                />
                            </li>
                            {FooterMenu.map(({ key, href, message }) => (
                                <li key={key}>
                                    <HyperLink
                                        variant="none"
                                        icon={key}
                                        href={href}
                                        message={message}
                                    />
                                </li>
                            ))}
                            <li>
                                <HyperLink
                                    variant="none"
                                    icon="logout"
                                    message="CLIENT_LOGOUT"
                                    onClick={logout}
                                />
                            </li>
                        </ul>
                    </Card>
                </div>

                <div className="client-content">
                    <Switch>
                        <UserRoute path="/cuenta/bancos"                                   component={BankPage}       exact />
                        <UserRoute path="/cuenta/datos"                                    component={AccountPage}    exact />
                        <UserRoute path="/cuenta/comprobantes"                             component={BlankPage}      exact />
                        <UserRoute path="/cuenta/comprobantes/facturas"                    component={InvoicePage}    exact />
                        <UserRoute path="/cuenta/comprobantes/facturas/:page"              component={InvoicePage}    exact />
                        <UserRoute path="/cuenta/comprobantes/recibos"                     component={ReceiptPage}    exact />
                        <UserRoute path="/cuenta/comprobantes/recibos/:page"               component={ReceiptPage}    exact />
                        <UserRoute path="/cuenta/comprobantes/notas-de-credito"            component={CreditNotePage} exact />
                        <UserRoute path="/cuenta/comprobantes/notas-de-credito/:page"      component={CreditNotePage} exact />
                        <UserRoute path="/cuenta/comprobantes/notas-de-debito"             component={DebitNotePage}  exact />
                        <UserRoute path="/cuenta/comprobantes/notas-de-debito/:page"       component={DebitNotePage}  exact />
                        <UserRoute path="/cuenta/pedidos/"                                 component={OrderList}      exact />
                        <UserRoute path="/cuenta/pedidos/:page"                            component={OrderList}      exact />
                        <UserRoute path="/cuenta/pedido/:order(\d+)"                       component={OrderPage}      exact />
                        <UserRoute path="/cuenta/pedido/:order(\d+)/:page"                 component={OrderPage}      exact />
                        <UserRoute path="/cuenta/grupo-pedidos/"                           component={OrderGroupList} exact />
                        <UserRoute path="/cuenta/grupo-pedidos/:page"                      component={OrderGroupList} exact />
                        <UserRoute path="/cuenta/grupo-pedido/:group(\d+)"                 component={OrderGroupPage} exact />
                        <UserRoute path="/cuenta/grupo-pedido/:group(\d+)/:page"           component={OrderGroupPage} exact />
                        <UserRoute path="/cuenta/consultas"                                component={QueryList}      exact />
                        <UserRoute path="/cuenta/consultas/crear"                          component={QueryCreate}    exact />
                        <UserRoute path="/cuenta/consultas/:type(abiertas|cerradas)"       component={QueryList}      exact />
                        <UserRoute path="/cuenta/consultas/:type(abiertas|cerradas)/:page" component={QueryList}      exact />
                        <UserRoute path="/cuenta/consulta/:query(\d+)"                     component={QueryPage}      exact />
                        <UserRoute path="/cuenta"                                          component={BlankPage}      exact />
                        <Redirect from="*" to="/cuenta" />
                    </Switch>
                </div>
            </div>
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout      : PropTypes.func.isRequired,
        isTablet    : PropTypes.bool.isRequired,
        isPotential : PropTypes.bool.isRequired,
        credential  : PropTypes.object.isRequired,
        dollar      : PropTypes.any.isRequired,
        location    : PropTypes.object.isRequired,
        page        : PropTypes.number.isRequired,
        type        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isTablet    : state.core.isTablet,
            isPotential : state.auth.isPotential,
            credential  : state.auth.credential,
            dollar      : state.store.header.dollar,
            page        : state.client.page,
            type        : state.client.type,
        };
    }
}

export default connect(ClientContainer.mapStateToProps, {
    logout,
})(ClientContainer);
