/**
 * The Links for the Menu
 */
export const MainMenu = [
    { key : "category", href : "/categorias",  message : "CATEGORIES_NAME", submenu : true  },
    { key : "offer",    href : "/ofertas",     message : "OFFERS_NAME",     submenu : true  },
    { key : "bike",     href : "/cilindradas", message : "BIKES_NAME",      submenu : true  },
    { key : "brand",    href : "/marcas",      message : "BRANDS_NAME",     submenu : false },
];

export const FooterMenu = [
    { key : "benefits", href : "/beneficios",             message : "BENEFITS_NAME"  },
    { key : "terms",    href : "/terminos-y-condiciones", message : "FOOTER_TERMS"   },
    { key : "about",    href : "/quienes-somos",          message : "ABOUT_NAME"     },
    { key : "contact",  href : "/cuenta/consultas/crear", message : "FOOTER_CONTACT" },
];



/**
 * The Links for the Voucher Menu
 */
export const VoucherMenu = [
    { key : "invoices",    href : "/cuenta/comprobantes/facturas",         message : "VOUCHERS_INVOICES"     },
    { key : "receipts",    href : "/cuenta/comprobantes/recibos",          message : "VOUCHERS_RECEIPTS"     },
    { key : "creditNotes", href : "/cuenta/comprobantes/notas-de-credito", message : "VOUCHERS_CREDIT_NOTES" },
    { key : "debitNotes",  href : "/cuenta/comprobantes/notas-de-debito",  message : "VOUCHERS_DEBIT_NOTES"  },
];

/**
 * The Links for the Client Menu
 * @param {Boolean} isPotential
 * @returns {Object[]}
 */
export function getClientMenu(isPotential) {
    const cartHref = isPotential ? "/cuenta/pedidos" : "/cuenta/grupo-pedidos";
    const result   = [
        { key : "bank",    href : "/cuenta/bancos", message : "BANKS_NAME",   submenu : null },
        { key : "account", href : "/cuenta/datos",  message : "ACCOUNT_NAME", submenu : null },
    ];
    if (!isPotential) {
        result.push({ key : "voucher", href : "/cuenta/comprobantes", message : "VOUCHERS_NAME", submenu : VoucherMenu });
    }
    result.push({ key : "cart",  href : cartHref,            message : "ORDERS_NAME",  submenu : null });
    result.push({ key : "query", href : "/cuenta/consultas", message : "QUERIES_NAME", submenu : null });
    return result;
}

/**
 * The Catalog File
 * @returns {String}
 */
export function getCatalogFile() {
    const baseUrl = process.env.REACT_APP_API.replace("app", "files");
    return baseUrl + "/source/catalogo.pdf";
}
